<template>
    <CCard bodyWrapper class="w-75" style="border-radius: 16px; height: auto; background-color: #D7C5FF; " >
        <div class="">
          <v-row align="center"
                   justify="center">
            <CCol sm="12" md="12" lg="3" class="w-100 p-4 d-flex align-items-center justify-content-center" align="center">
                  <a  style="font-weight:bolder; font-size: 18px;">{{eventObject.eventName}}</a>
            </CCol>
            <CCol sm="4" md="12" lg="6" class="w-100 p-1 d-flex align-items-center justify-content-center">
              <b-container class="justify-content-center">
                <b-row>
                  <b-col cols="12" md="12" class="w-100 d-flex align-items-center justify-content-center">
                    <a style="font-weight:bolder; font-size: 18px;">วันสอบ{{` ${timeThaiFormat(eventObject.startAt)}`}} <a style="font-weight:bolder; font-size: 18px;"></a></a>
                  </b-col>
                </b-row>
                  <b-row v-if="eventObject.eventDetail.srcExam.service ==='cmuexam'">
                    <b-col class="w-100 d-flex align-items-center justify-content-center">
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../../public/img/service/exam-logo.png"
                        height="80"
                        width="90"
                        class=""
                      />
              
                    </b-col>
                  </b-row>
                  <b-row v-if="eventObject.eventDetail.srcExam.service ==='cmumango'">
                    <b-col class="w-100 d-flex align-items-center justify-content-center">
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../../public/img/service/mango-logo.png"
                        height="30"
                        width="70"
                        class="m-4"
                      />
              
                    </b-col>
                  </b-row>
                  <b-row v-if="eventObject.eventDetail.srcExam.service ==='cmumoodle'">
                    <b-col class="w-100 d-flex align-items-center justify-content-center">
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../../public/img/service/moodle-logo.png"
                        height="40"
                        width="90"
                        class="m-4"
                      />
              
                    </b-col>
                  </b-row>
                  <b-row v-if="eventObject.eventDetail.srcExam.service ==='cmucanvas'">
                    <b-col class="w-100 d-flex align-items-center justify-content-center">
                      <img
                        alt="service-logo"
                        color="outline-pastelpurple2"
                        src="../../../public/img/service/canvas-cmu-logo.png"
                        height="80"
                        width="90"
                        class=""
                      />
              
                    </b-col>
                  </b-row>

                <b-row>
                  <b-col cols="12" md="12" class="w-100 d-flex align-items-center justify-content-center">
                    <a style="font-size: 10px;">สร้างเมื่อ {{` ${timeThaiFormat(eventObject.createdAt)}`}}</a>
                  </b-col>
                </b-row>
              </b-container>
            </CCol>
            
            <CCol class="w-50  d-flex align-items-center justify-content-center">
                <div class="text-right">
                <CButton color="warning" class="float-right" style="border-radius: 3px;" v-on:click="btnClicked(eventObject.eventid ? eventObject.eventid : eventObject._id)">
                   <b class="p-2" >monitor</b>
                </CButton>
                </div>
            </CCol>
          </v-row>
        </div>
    </CCard>
</template>

<script>
import Swal from "sweetalert2"
import examManagerApi from "@/services/apiServices"

export default {
  name: "CardsEvent2",
  props: {
    eventObject: Object,
  },
  data() {
    return {
      eventName: "",
    }
  },
  created() {
    console.log("eventObject:",this.eventObject)
  },
  methods: {
    btnClicked(index) {
      this.$router.push({ path: `events/${index}/detail` })
    },
    async confirmDelete(eventId) {
      Swal.fire({
        title: "คุณต้องการที่จะลบEvent?",
        text: "คุณจะไม่สามารถกู้ข้อมูลกลับได้!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteReport(eventId)
          this.deleteEvent(eventId)
        }
      })
    },
    async deleteEvent(eventId) {
      let deleteEvent = await examManagerApi.deleteEvent(eventId)
      if (deleteEvent) {
        Swal.fire("Deleted!", "Your Event has been deleted.", "success")
        this.$emit("afterDeleteEvent", true)
      }
    },
    async deleteReport(eventId) {
      let eventReport = await examManagerApi.getReportWithEventId(eventId)
      console.log(eventReport)
      let reportID = eventReport.data._id
      await examManagerApi.deleteReport(reportID)
    },
    timeThaiFormat(timestamp) {
      let option = { year: "numeric", month: "long", day: "numeric", weekday: "long", hour: "numeric", minute: "numeric", second: "numeric" }
      return new Date(parseInt(timestamp) * 1000).toLocaleString("th-TH", option)
    },
    timeFormat(timestamp) {
      let timeFormat = {  hour: "numeric", minute: "numeric", second: "numeric" }

      return new Date(parseInt(timestamp) * 1000).toLocaleString("th-TH", timeFormat)
    },
  },
}
</script>
